const toSearchString = (searchParams: URLSearchParams) =>
  searchParams.size
    ? `?${searchParams
        .toString()
        .replaceAll("%28", "(")
        .replaceAll("%29", ")")
        .replaceAll("%7E", "~")
        .replaceAll("%21", "!")}`
    : "";

export const hrefWithEditedSearchParams = (
  cb: (searchParams: URLSearchParams) => void,
  withHost: boolean,
) => {
  const searchParams = new URLSearchParams(window.location.search);
  cb(searchParams);
  const { protocol, host, pathname, hash } = window.location;
  const prefix = withHost ? `${protocol}//${host}` : "";
  return `${prefix}${pathname}${toSearchString(searchParams)}${hash}`;
};
